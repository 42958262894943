import React from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const ObjectiveBlock = ({
  theme,
  keyValue,
  className,
  children,
}) => (
  <div className={cx(styles.objectiveBlock, theme && styles[theme], className && className)}>
    <div className={styles.objectiveBlockKeyValue}>
      <p>{keyValue}</p>
    </div>
    <div className={styles.objectiveBlockContent}>
      {children}
    </div>
  </div>
)

export default ObjectiveBlock
