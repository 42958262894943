import React from "react"
import { RightArrow } from "../Icons"

import cx from "classnames"
import styles from "./styles.module.scss"

const ImageBlock = ({
  linkUrl,
  linkText = "Learn more",
  theme,
  img,
  className,
  children,
}) => {
  let arrowColour;
  switch(theme) {
    case "pink":
      arrowColour = 'purple';
      break;
    case "dark":
      arrowColour = 'white';
      break;
    default:
      arrowColour = 'pink';
      break;
  }
  return (
    <a className={styles.imgBlockOuter} href={linkUrl}>
      <div className={cx(styles.imgBlock, styles[theme], className && className)}>
        <div className={styles.imgBlockImg}>
          <img alt="to describe the block beneath" className={styles.imgBlockImgDisplay} src={img} />
        </div>
        <div className={styles.imgBlockContent}>
          { children }
          <div>
            <a className={styles.imgBlockLink} href={linkUrl}>{linkText} <RightArrow className={styles.arrowRight} fill={arrowColour}/></a>
          </div>
        </div>
      </div>
    </a>
  );
}

export default ImageBlock
