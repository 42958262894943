import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock,
} from "../../components/Heros"
import {
  DualImageBlock,
  RightDualComponent,
  DualComponentBlock
} from "../../components/Grid"
import ObjectiveBlock from "../../components/ObjectiveBlock"
import Spacer from "../../components/Spacer"
import OurCarriers from "../../components/OurCarriers"
import TriImageBlock from "../../components/TriImageBlock"
import ImageBlock from "../../components/ImageBlock"
import { RightArrow } from "../../components/Icons"
import RequestDemo from "../../components/RequestDemo"

import homeHeroImg from "../index/img/homehero.jpg"
import deliveryOptionsImg from "../index/img/womanonphone.png"
import globeImg from "../index/img/globe.png"
import planeImg from "../index/img/plane.png"
import parcelPackingImg from "./images/parcelPacking.png"
import reactphone from "./images/reactphone.png"
import dhl from "../../components/OurCarriers/images/dhl.svg"
import hermes from "../../components/OurCarriers/images/Hermes.svg";
import parcelforce from "../../components/OurCarriers/images/parcelforce.svg";
import fedex from "../../components/OurCarriers/images/FedEx_Express.svg";

import styles from "./styles.module.scss"

const CarrierLogos = () => (
  <div className={styles.carrierLogos}>
    <div className={styles.carrierLogosHermes}>
      <img alt="carrier logo" src={hermes} />
    </div>
    <div className={styles.carrierLogosParcelForce}>
      <img alt="carrier logo" src={parcelforce} />
    </div>
    <div className={styles.carrierLogosDhl}>
      <img alt="carrier logo" src={dhl} />
    </div>
    <div className={styles.carrierLogosFedex}>
      <img alt="carrier logo" src={fedex} />
    </div>
  </div>
)

const ReactLineLeft = () => (
  <div className={styles.reactPhoneHolder}>
    <div className={styles.reactPhoneBg} />
    <img className={styles.reactPhone} src={reactphone} alt="phone using react" />
  </div>
)

const ReactLineRight = () => (
  <div className={styles.reactRight}>
    <h3>Carriers and React line.</h3>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.</p>
    <a className="link-pink" href="https://www.test.com">See how carriers work with Sorted.React <RightArrow /></a>
  </div>
)

const Carriers = () => (
  <Layout>
    <Seo title="Sorted | Carriers" keywords={[`Carriers`]} />
    <PrimaryHero theme="pink" img={homeHeroImg}>
      <HeroCopyBlock
        title={["Carriers,","Sorted."]}
      >
        <p>If you’re a carrier, you need a partner that makes your life easier not harder. Carriers are our biggest allies. And we want to make sure they are in control.</p>
      </HeroCopyBlock>
    </PrimaryHero>

    <div className="container">
      <Spacer size="medium" sides="topbottom">
        <h2 className="text-pink text-center">Carrier headline</h2>
        <p class="text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolo.</p>
      </Spacer>
      <Spacer size="large" sides="bottom">
        <TriImageBlock>
          <ObjectiveBlock theme="grey" keyValue="100%">
            <h4>Objective A</h4>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
          </ObjectiveBlock>
          <ObjectiveBlock theme="purple" keyValue="33%">
            <h4>Objective A</h4>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
          </ObjectiveBlock>
          <ObjectiveBlock theme="green" keyValue="80%">
            <h4>Objective A</h4>
            <p>Lorem ipsum dolor sit amet, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
          </ObjectiveBlock>
        </TriImageBlock>
      </Spacer>
    </div>

    <DualImageBlock img={parcelPackingImg} theme="purple" maxHeight={700}>
      <h3>Carriers and hero integration line.</h3>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.</p>
      <a className="link-pink" href="https://www.test.com">See how carriers work with Sorted.HERO <RightArrow /></a>
    </DualImageBlock>

    <div className="bg-light">
      <Spacer size="large" sides="topbottom">
        <div className="container-large-fluid">
          <RightDualComponent
            rightComponent={<CarrierLogos />}
            theme="grey"
          >
            <div className={styles.proLineContent}>
              <h3>Carriers and PRO line.</h3>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est</p>
              <a className="link-white" href="https://www.test.com">See how carriers work with sorted PRO <RightArrow fill="white" /></a>
            </div>
          </RightDualComponent>
        </div>
      </Spacer>
    </div>

    <DualComponentBlock
      className={styles.reactComponentHolder}
      leftComponent={<ReactLineLeft />}
      rightComponent={<ReactLineRight />}
    />

    <div className="container">
      <Spacer size="large" sides="topbottom">
        <h3 className="text-pink text-center">Integration with local and global carriers.</h3>
        <p className="text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolo.</p>
        <OurCarriers />
      </Spacer>
    </div>

    <div className="container">
      <Spacer size="large" sides="bottom">
        <TriImageBlock>
          <ImageBlock
            linkUrl="https://www.kieranvenison.co.uk"
            theme="light"
            img={deliveryOptionsImg}
          >
            <h3>Delivery options.</h3>
            <p>An API solution dynamically displaying carrier services at checkout with SortedHero.</p>
          </ImageBlock>
          <ImageBlock
            linkUrl="https://www.kieranvenison.co.uk"
            theme="pink"
            img={planeImg}
          >
            <h3>Modern world warehouse.</h3>
            <p>Single-integration carier and shipping.</p>
          </ImageBlock>
          <ImageBlock
            linkUrl="https://www.kieranvenison.co.uk"
            theme="dark"
            img={globeImg}
          >
            <h3>Powerful delivery tracking.</h3>
            <p>Proactive control for customer service teams, self-service  convinience for</p>
          </ImageBlock>
        </TriImageBlock>
      </Spacer>
    </div>

    <RequestDemo />
  </Layout>
)

export default Carriers
