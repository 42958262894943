import React from "react"

import styles from "./styles.module.scss"

const TriImageBlock = ({ children }) => (
  <div className={styles.triImgBlock}>
    { children }
  </div>
);

export default TriImageBlock
